import React from "react";

export const Modal = ({children, show = false}) => {
    const display = show ? "modal d-block" : "modal d-none";

    return (
        <div className={display}>
            <div className="modal-dialog">
                <div className="modal-content py-3 px-4">
                    {children}
                </div>
            </div>
        </div>
    );
};
