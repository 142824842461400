import {makeAutoObservable, runInAction} from 'mobx';
import {MCalcService} from "../mcalcService";
import {config} from "../config";

export class NotificationStore {
    notify = false;

    constructor() {
        this.mcalcService = new MCalcService(config.apiUrl);

        makeAutoObservable(this);
    }

    async subscribe(userId) {
        await this.mcalcService.subscribe(userId);
        await this.isSubscribed(userId);
    }

    async unsubscribe(userId) {
        await this.mcalcService.unsubscribe(userId);
        await this.isSubscribed(userId);
    }

    async isSubscribed(userId) {
        const {data} = await this.mcalcService.getSubscriptionCount(userId);

        runInAction(() => this.notify = data.count > 0)
    }
}
