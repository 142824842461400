import {inject, observer} from "mobx-react";

export * from './authStore';
export * from './usersStore';
export * from './entriesStore';
export * from './notificationStore';

export function withStores(stores, fn) {
    return inject(...stores)(observer(fn))
}
