import React, {useState} from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import {Doughnut} from "react-chartjs-2";
import {withStores} from "../stores";

ChartJS.register(ArcElement, Tooltip, Legend);

export const MChart = withStores(['entriesStore'],
    ({entriesStore}) => {
        const [hidden, setHidden] = useState(true);
        const s = getComputedStyle(document.body);
        const expense = entriesStore.expenseTotal;
        const income = entriesStore.incomeTotal;

        const expensePercent = expense / income * 100;
        let incomePercent = (income - expense) / income * 100;

        if (incomePercent < 0) {
            incomePercent = 0
        }

        const incomeColor = s.getPropertyValue('--primary');
        const expenseColor = s.getPropertyValue('--danger');

        const datasets = [{
            data: [
                incomePercent.toFixed(2),
                expensePercent.toFixed(2)
            ],
            backgroundColor: [incomeColor, expenseColor]
        }];

        const data = {
            labels: ['Net', 'Costs'],
            datasets
        };

        const options = {
            tooltips: {
                enabled: true,
                mode: 'single',
                callbacks: {
                    label(tooltipItems, data) {
                        const i = tooltipItems.index;
                        const d = data.datasets[0].data[i];

                        return ` ${data.labels[i]} ${d}%`;
                    }
                }
            },
        };

        return (
            <div className="card mb-2">
                <div  className="card-header d-flex justify-content-between align-items-center text-muted">
                    <span><i className="fas fa-chart-pie mr-1"/> Net/Cost Chart</span>
                    <button onClick={() =>  setHidden(!hidden)} className="btn btn-outline-secondary btn-sm text-muted">
                        {hidden ? 'Expand' : 'Collapse'}
                    </button>
                </div>
                <div className="card-body" hidden={hidden}>
                    <Doughnut data={data} options={options}/>
                </div>
            </div>
        )
    }
);
