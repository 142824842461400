import {makeAutoObservable, runInAction} from 'mobx';
import {MCalcService} from "../mcalcService";
import {config} from "../config";


const userInitialState = {
    username: '',
    email: '',
    userId: ''
};

export class AuthStore {
    user;
    message;

    constructor() {
        this.mcalcService = new MCalcService(config.apiUrl);
        this.user = localStorage.getItem('user')
            ? JSON.parse(localStorage.getItem('user'))
            : userInitialState;
        this.message = null;

        makeAutoObservable(this);
    }

    login = async (email, password) => {
        try {
            const {data} = await this.mcalcService.login(email, password);

            localStorage.setItem('user', JSON.stringify(data));

            runInAction(() => {
                this.user = data
                this.showMessages('Login successful');
            });



        } catch (e) {
            runInAction(() => {
                this.user = userInitialState

                const errorMessage = e.response ? e.response.data.error : 'server error';
                this.showMessages(errorMessage, 'danger');
            });
        }
    };

    logout = async () => {
        try {
            await this.mcalcService.logout()
        } catch (e) {
            console.log(e)
        }

        localStorage.setItem('user', "");

        runInAction(() => {
            this.user = userInitialState;

            this.showMessages('Logout successful');
        });


    };

    showMessages(msg, type = 'success', time = 2000) {
        this.message = {
            text: msg,
            type
        };

        setTimeout(() => this.message = undefined, time)
    }
}
