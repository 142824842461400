import React, {useEffect, useState} from "react";

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import {Expense, Income} from "../models";
import {withStores} from "../stores";
import {Modal} from "./Modal";
import {Switch} from "./Switch";

const categories = [
    {label: 'Housing', type: 'expense'},
    {label: 'Education', type: 'expense'},
    {label: '❤️ Kids ❤️', type: 'expense'},
    {label: 'CarSharing', type: 'expense'},
    {label: 'Transportation', type: 'expense'},
    {label: 'Household', type: 'expense'},
    {label: 'Food', type: 'expense'},
    {label: 'Utilities', type: 'expense'},
    {label: 'Healthcare', type: 'expense'},
    {label: 'Hygiene', type: 'expense'},
    {label: 'Investing', type: 'expense'},
    {label: 'Saving', type: 'expense'},
    {label: 'Debt', type: 'expense'},
    {label: 'Balancing', type: 'expense'},
    {label: 'Personal Spending', type: 'expense'},
    {label: 'Recreation', type: 'expense'},
    {label: 'Active Income', type: 'income'},
    {label: 'Passive Income', type: 'income'},
    {label: 'Portfolio Income', type: 'income'},
];

export const EntryForm = withStores(['entriesStore', 'authStore'],
    ({entriesStore, authStore}) => {
        const editingEntry = entriesStore.entry;
        const [isIncome, setIsIncome] = useState(false);
        const [isRecurrent, setIsRecurrent] = useState(false);
        const [desc, setDesc] = useState('');
        const [date, setDate] = useState(new Date());
        const [show, setShow] = useState(false);
        const [isBankExpenseDiff, setIsBankExpenseDiff] = useState(false);

        const [category, setCategory] = useState('Uncategorized');
        const [amountInputs, setAmountInputs] = useState(1);
        const type = isIncome ? 'Income' : 'Expense';
        const actionType = editingEntry ? 'Update' : 'Submit';
        useEffect(() => {
            if (editingEntry) {
                setShow(true);
                setIsIncome(editingEntry.type === 'income');
                setIsRecurrent(editingEntry.isRecurrent);
                setDesc(editingEntry.desc);
                setDate(new Date(editingEntry.created));
                setCategory(editingEntry.category);
            }
        }, [editingEntry])

        const addEntry = amount => {
            if (isIncome) {
                entriesStore.addEntry(new Income(desc, amount, date, category, isRecurrent));
            } else {
                if (isBankExpenseDiff) {
                    entriesStore.addBalancingEntry(
                        new Expense(desc, amount, date, category, isRecurrent),
                        authStore.user.userId,
                    ).catch(console.error);
                } else {
                    entriesStore.addEntry(new Expense(desc, amount, date, category, isRecurrent))
                        .catch(console.error);
                }
            }
        };

        const submit = e => {
            e.preventDefault();
            const form = e.target;
            let amount = Array.from(form.elements).reduce((result, el) => {
                if (el.name.startsWith('amount') && el.value) {
                    result += parseFloat(el.value)
                }

                return result;
            }, 0);

            if (desc && amount) {
                amount = parseFloat(amount.toFixed(2));

                if (editingEntry) {
                    entriesStore.editEntry({
                        desc,
                        category,
                        isRecurrent,
                        value: amount,
                        type: type.toLowerCase(),
                        created: date.toISOString(),
                        userId: editingEntry.userId,
                        _id: editingEntry._id
                    });
                } else {
                    addEntry(amount);
                }

                form.reset();
                stateReset();
            }
        };

        const stateReset = () => {
            setIsBankExpenseDiff(false);
            setIsIncome(false);
            setIsRecurrent(false);
            setDesc('');
            setDate(new Date());
            setShow(false);
            setCategory('Uncategorized');
            setAmountInputs(1);
            entriesStore.setEditingEntry(null);
        };

        const openModalForBankExpense = () => {
            setShow(true);
            setIsBankExpenseDiff(true);
            setIsIncome(false);
            setIsRecurrent(false);
            setCategory('Balancing');
            setDesc('Balancing expense entry');
        };

        return (
            <div>
                <button className="btn btn-danger btn-sm mr-2" onClick={openModalForBankExpense}>
                    <i className="fas fa-plus mr-2"/>Balancing Expense
                </button>
                <button className="btn btn-primary btn-sm" onClick={() => setShow(true)}>
                    <i className="fas fa-plus mr-2"/>New Entry
                </button>
                <Modal show={show}>
                    <form onSubmit={submit}>
                        <div className="input-group mb-2 justify-content-between">
                            <button type="button" className="btn btn-link" onClick={() => stateReset()}>
                                <i className="fas fa-times"/>
                            </button>
                            <div className={`d-${isBankExpenseDiff ? 'none' : 'flex'}`}>
                                <Switch checked={isIncome} onChange={() => setIsIncome(!isIncome)} labelText={type}/>
                                <div className="ml-2"/>
                                <Switch checked={isRecurrent} onChange={() => setIsRecurrent(!isRecurrent)}
                                        labelText={'Recurrent'}/>
                            </div>
                        </div>
                        {!isBankExpenseDiff && <div className="input-group mb-2">
                            <div className="input-group-prepend">
                                <span className="input-group-text"><i className="fas fa-info"/></span>
                            </div>
                            <input
                                className="form-control"
                                placeholder="e.g. Salary, Rent, Utils"
                                value={desc}
                                onChange={e => setDesc(e.target.value)}
                            />
                        </div>}
                        {
                            Array.from(Array(amountInputs).keys()).map(k => <div key={k} className="input-group mb-2">
                                <div className="input-group-prepend">
                                    <span className="input-group-text"><i className="fas fa-euro-sign"/></span>
                                </div>
                                <input
                                    defaultValue={k === 0 && editingEntry?.value}
                                    min="0.1"
                                    name={"amount-" + k}
                                    className="form-control"
                                    type="number"
                                    placeholder="e.g. 1000"
                                    step="0.01"
                                />
                            </div>)
                        }
                        {!isBankExpenseDiff && <>
                            <div className="btn-group mb-2 btn-block">
                                {amountInputs > 1 &&
                                    <button className="btn btn-secondary"
                                            onClick={() => setAmountInputs(amountInputs - 1)}>
                                        <i className="fas fa-minus mr-2"/>
                                    </button>}
                                <button type="button" className="btn btn-secondary"
                                        onClick={() => setAmountInputs(amountInputs + 1)}><i
                                    className="fas fa-plus mr-2"/>
                                </button>
                            </div>
                            <CategoriesSelect type={type} category={category} setCategory={setCategory}/>
                            <div className="input-group input-group-date mb-2">
                                <div className="input-group-prepend">
                                    <span className="input-group-text"><i className="fas fa-calendar"/></span>
                                </div>
                                <div className="datePickerWrapper">
                                    <DatePicker className="form-control" dateFormat="dd/MM/yyyy" selected={date}
                                                onChange={d => setDate(d)}/>
                                </div>

                            </div>
                        </>}
                        <div className="btn-group btn-block my-2">
                            {editingEntry &&
                                <button className="btn btn-danger w-50" type="button" onClick={() => entriesStore
                                    .removeEntry(editingEntry._id)
                                    .then(stateReset)
                                }>
                                    <i className="fas fa-trash mr-1 text-white"/> Delete
                                </button>}
                            <button className="btn btn-primary w-50" type="submit">
                                <i className="fas fa-check mr-1 text-white"/> {actionType}
                            </button>
                        </div>
                    </form>
                </Modal>
            </div>
        );
    }
);

const CategoriesSelect = ({type, category, setCategory}) => {
    return (
        <div className="input-group mb-2 mt-md-0">
            <div className="input-group-prepend">
                <span className="input-group-text"><i className="fas fa-list"/></span>
            </div>
            <select
                className="form-control"
                value={category}
                onChange={e => setCategory(e.target.value)}>
                <option value="Uncategorized">Uncategorized</option>
                {categories.filter(cat => cat.type === type.toLowerCase()).map(opt => (
                    <option key={opt.label} value={opt.label}>{opt.label}</option>
                ))}
            </select>

        </div>
    )
};

