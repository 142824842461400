import axios from 'axios';
import qs from 'qs';

export class MCalcService {
    baseURL = "";
    http = null;

    constructor(url) {
        this.baseURL = url;
        this.http = axios.create({
            baseURL: url,
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials': true
            }
        });
    }

    login(email, password) {
        return this.http.post(`${this.baseURL}/login`, {email, password});
    }

    logout() {
        return this.http.get(`${this.baseURL}/logout`);
    }

    async getEntries(query = {}) {
        const {data} = await this.http.get(`${this.baseURL}/auth/entries?${qs.stringify(query)}`);

        return data || [];
    }

    async getUsers() {
        const {data} = await this.http.get(`${this.baseURL}/auth/users`);

        return data || [];
    }

    async getAverages() {
        const {data} = await this.http.get(`${this.baseURL}/auth/averages`);

        return data || {};
    }

    async getCategories() {
        const {data} = await this.http.get(`${this.baseURL}/auth/categories`);

        return data || [];
    }

    async createEntry(item) {
        const {data} = await this.http.post(`${this.baseURL}/auth/entries`, item);

        return data || {};
    }

    async editEntry(item) {
        const {data} = await this.http.put(`${this.baseURL}/auth/entries`, item);

        return data || {};
    }

    async deleteEntry(id) {
        const {data} = await this.http.delete(`${this.baseURL}/auth/entries/${id}`);

        return data || {};
    }

    async getSubscriptionCount(userId) {
        return this.http.get(`${this.baseURL}/auth/users/${userId}/subscriptions`)
    }

    async subscribe(userId) {
        const pushSWUrl = `${process.env.PUBLIC_URL}/pushSW.js`;
        const registration = await navigator.serviceWorker.getRegistration(pushSWUrl);
        const applicationServerKey = base64ToUnit8('BCDj-AQte5cNzQR1D6YL7fzIwH5VA_-u0uLwGOb0wzEmSXte-aicMBH7OTeIaPNoXZonLVsbtScp3MfIICN9yWw');
        const subscribeOptions = {userVisibleOnly: true, applicationServerKey};
        const subscription = await registration.pushManager.subscribe(subscribeOptions);

        return this.http.post(`${this.baseURL}/auth/users/${userId}/subscribe`, {userId, subscription})
    }

    async unsubscribe(userId) {
        return this.http.get(`${this.baseURL}/auth/users/${userId}/unsubscribe`)
    }
}

function base64ToUnit8(base64Str) {
    const padding = '='.repeat((4 - (base64Str.length % 4)) % 4);
    const base64 = (base64Str + padding)
        .replace(/-/g, '+')
        .replace(/_/g, '/');

    const bytesChars = atob(base64);

    return Uint8Array.from([...bytesChars].map(c => c.charCodeAt(0)));
}
