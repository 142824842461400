import React, {useState} from "react";
import {withStores} from "../stores";
import {Navigate} from "react-router-dom";

export const Login = withStores(['authStore'],
    ({authStore}) => {
        const [showPass, setShowPass] = useState(false);
        const [email, setEmail] = useState('');
        const [password, setPassword] = useState('');

        const submit = () => {
            authStore.login(email, password);

            setEmail('');
            setPassword('');
            setShowPass(false);
        };

        if (authStore.user && authStore.user.email !== '') {
            return <Navigate to="/entries" replace/>
        } else {
            return (
                <div className="container align-self-center login">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-6 col-lg-4">
                            <div>
                                <div className="card py-4">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-center align-items-center flex-column">
                                            <img src="/android-chrome-192x192.png" alt="logo" className="logo"/>
                                        </div>
                                        <EmailInput email={email} setEmail={setEmail}/>
                                        <PasswordInput
                                            showPass={showPass}
                                            password={password}
                                            setPassword={setPassword}
                                            setShowPass={setShowPass}/>
                                        <button className="btn btn-primary btn-block" onClick={() => submit()}>Login
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
);

const EmailInput = ({email, setEmail}) => (
    <div className="input-group mb-2 mt-4">
        <div className="input-group-prepend">
            <span className="input-group-text"><i className="fas fa-at"/></span>
        </div>
        <input
            type="email"
            placeholder="email"
            className="form-control"
            value={email}
            onChange={e => setEmail(e.target.value)}/>
    </div>
);

const PasswordInput = ({showPass, password, setPassword, setShowPass}) => (
    <div className="input-group mb-2 input-group-password">
        <div className="input-group-prepend">
            <span className="input-group-text"><i className="fas fa-lock"/></span>
        </div>
        <input
            type={showPass ? "text" : "password"}
            placeholder="password"
            className="form-control"
            value={password}
            onChange={e => setPassword(e.target.value)}/>

        <i className={`fa fa-eye show-pass-toggle ${showPass ? "active" : ""}`}
           onClick={() => setShowPass(!showPass)}/>
    </div>
);

export const Protected = withStores(['authStore'],
    ({authStore, children}) => {
        if (authStore.user && authStore.user.email !== '') {
            return children;
        } else {
            return <Navigate to="/" replace/>
        }
    }
);
