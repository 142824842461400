import {inject, observer} from "mobx-react";
import React, {useEffect} from "react";
import {currencyFormatter} from "./utils";

export const Overview = inject('entriesStore')(
    observer(({entriesStore: {
        entries,
        averages:{income = 0, expense = 0,surplus = 0},
        total,
        expenseTotal,
        incomeTotal,
        getAverages
    }}) => {
        useEffect(() => {
            getAverages();
        }, [entries, getAverages]);

        return (
            <div className="row text-white mb-2 overview">
                <div className="col-4">
                    <div className="card bg-success">
                        <div className="card-body text-center">
                            <i className="fas fa-plus"/>
                            <h5>{currencyFormatter.format(incomeTotal)}</h5>
                            <i className="fas fa-ellipsis-h"/>
                            <h6>Avg: {currencyFormatter.format(income)}</h6>
                        </div>
                    </div>
                </div>
                <div className="col-4">
                    <div className="card bg-danger">
                        <div className="card-body text-center">
                            <i className="fas fa-minus"/>
                            <h5>{currencyFormatter.format(expenseTotal)}</h5>
                            <i className="fas fa-ellipsis-h"/>
                            <h6>Avg: {currencyFormatter.format(expense)}</h6>
                        </div>
                    </div>
                </div>
                <div className="col-4">
                    <div className={`card bg-${parseFloat(total) >= 0 ? 'primary' : 'danger'}`}>
                        <div className="card-body text-center">
                            <i className="fas fa-piggy-bank"/>
                            <h5>{currencyFormatter.format(total)}</h5>
                            <i className="fas fa-ellipsis-h"/>
                            <h6>Avg: {currencyFormatter.format(surplus)}</h6>
                        </div>
                    </div>
                </div>
            </div>
        )
    })
);
