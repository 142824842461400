import {runInAction, makeAutoObservable} from 'mobx';

import {MCalcService} from "../mcalcService";
import {config} from "../config";

function logger(...args) {
    console.log('====================================');
    console.log(...args);
    console.log('====================================');
}

export class EntriesStore {
    query = {};
    entries = [];
    categories = [];
    averages = {income: 0, expense: 0, surplus: 0};
    entry = null;

    constructor() {
        this.mcalcService = new MCalcService(config.apiUrl);

        makeAutoObservable(this);
    }

    get csv() {
        if (this.entries.length) {
            const headers = Object.keys(this.entries[0]).map(k => k.toUpperCase()).join(';') + ';\n';

            return headers + this.entries.reduce((csv, entry) => csv.concat(Object.values(entry).join(';')) + ';\n', '')
        }

        return '';
    }

    get total() {
        return this.entries
            .reduce((res, n) => (n.type === 'income' ? (res += n.value) : (res -= n.value)), 0)
    }

    get expenseTotal() {
        return this.entries
            .reduce((res, n) => {
                if (n.type === 'expense') {
                    res += parseFloat(n.value);
                }

                return res;
            }, 0);
    }

    get pages() {
        return chunk(this.entries, 10)
    }

    get incomeTotal() {
        return this.entries
            .reduce((res, n) => {
                if (n.type === 'income') {
                    res += parseFloat(n.value);
                }

                return res;
            }, 0);
    }

    async addEntry(item) {
        try {
            await this.mcalcService.createEntry(item);
            await this.getEntries(this.query);
        } catch (e) {
            logger("Failed to create item", e);
        }
    }

    async addBalancingEntry(item, userId) {
        try {
            const expenses = await this.mcalcService.getEntries({...this.query, userId})
            item.value = item.value - expenses.filter(({type}) => type === 'expense').reduce((res, n) => {
                return res + parseFloat(n.value);
            }, 0);

            if (item.value <= 0) {
                throw new Error('No need to balance');
            }

            item.created = expenses?.[0]?.created;

            await this.mcalcService.createEntry(item);
            await this.getEntries(this.query);
        } catch (e) {
            logger("Failed to create item", e);
        }
    }

    async editEntry(item) {
        try {
            await this.mcalcService.editEntry(item);
            await this.getEntries(this.query);
            this.entry = null;
        } catch (e) {
            logger("Failed to edit item", e);
        }
    }

    async removeEntry(id) {
        try {
            await this.mcalcService.deleteEntry(id);
            await this.getEntries(this.query)
        } catch (e) {
            logger('Failed to remove item', e)
        }
    }

    setEditingEntry(entry) {
        runInAction(() => this.entry = entry);
    }

    getEntries = async query => {
        this.query = query.isRecurrent ? query : Object.assign(this.query, query);

        try {
            const data = await this.mcalcService.getEntries(this.query);

            runInAction(() => (this.entries = data));
        } catch (e) {
            logger('Failed to fetch entries', e);

            if(e.response && e.response.status === 401) {
                localStorage.setItem('user', null);
            }
        }
    }

    getAverages = async () => {
        try {
            const data = await this.mcalcService.getAverages();

            runInAction(() => (this.averages = data));
        } catch (e) {
            logger('Failed to fetch entries', e);

            if(e.response && e.response.status === 401) {
                localStorage.setItem('user', null);
            }
        }
    }

    getCategories = async () => {
        try {
            const data = await this.mcalcService.getCategories();

            runInAction(() => (this.categories = data));
        } catch (e) {
            logger('Failed to fetch categories', e);

            if(e.response && e.response.status === 401) {
                localStorage.setItem('user', null);
            }
        }
    }
}

function chunk(array, size) {
    const chunked_arr = [];
    let index = 0;
    while (index < array.length) {
        chunked_arr.push(array.slice(index, size + index));
        index += size;
    }
    return chunked_arr;
}
