import * as moment from 'moment';

export class Entry {
    type = '';
    desc = '';
    created = '';
    userId = '';
    category = '';
    value = 0;
    isRecurrent = false;

    constructor(desc, value, date, category, isRecurrent = false) {
        this.desc = desc;
        this.value = parseFloat(value);
        this.created = moment(date).toISOString();
        this.category = category;
        this.isRecurrent = isRecurrent;

        this.userId = JSON.parse(localStorage.getItem('user')).userId;
    }
}
