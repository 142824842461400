import React, {useState} from "react";
import moment from "moment";
import {withStores} from "../stores";
import {EntryForm} from "./EntryForm";
import {currencyFormatter} from "./utils";

export const List = withStores(['entriesStore', 'usersStore'],
    ({entriesStore, usersStore}) => {
        const [page, setPage] = useState(0);

        const pageTotal = Math.ceil(entriesStore.entries.length / 10) || 0;
        const pageLinks = Array(pageTotal).fill('p') || [];
        const mappedUsers = usersStore.users?.reduce((r,u) => {
            r[u._id] = u;

            return r;
        }, {});

        return (
            <div className="card">
                <div className="card-header bg-white d-flex justify-content-between">
                    <CSVExportBtn csv={entriesStore.csv}/>
                    <EntryForm/>
                </div>
                <div className="card-body">
                    <table className="table table-hover">
                        <thead>
                        <tr>
                            <th>By/Date</th>
                            <th>Description</th>
                            <th className="text-right">Amount</th>
                            <th/>
                        </tr>
                        </thead>
                        <tbody>
                        {entriesStore.pages.length ? entriesStore.pages?.[page]?.map((entry, i) => (
                            <ListItem
                                key={entry._id}
                                {...entry}
                                user={mappedUsers[entry.userId]}
                                setEditingEntry={entriesStore.setEditingEntry.bind(entriesStore)}/>
                        )) : <tr/>}
                        </tbody>
                    </table>
                </div>
                {pageTotal > 1 ? (
                    <div className="card-footer bg-white">
                        <Pagination pageLinks={pageLinks} page={page} setPage={setPage}/>
                    </div>
                ) : null}
            </div>
        )
    }
);

const ListItem = ({_id, desc, value, created, category, type, setEditingEntry, user, userId, isRecurrent}) => {
    return (
        <tr>
            <td className="align-middle tip d-flex">
            <span className="d-flex align-items-center">
                {user?.imageSrc
                    ? <img className="rounded-circle mr-3" width={30} src={user.imageSrc}/>
                    : <div className="user-placeholder mr-3">{user?.username?.substr(0,2)}</div>
                }
                <strong className={`text-${type === 'income' ? 'success' : 'danger'}`}>
                    {moment(created).format('DD/MM')}
                </strong>
            </span>
                <span className="tip-text">
                {moment(created).format('dddd L')}
            </span>
            </td>
            <td className="align-middle tip">
                {desc}
                <span className="tip-text">{category}</span>
            </td>

            <td className="text-right align-middle">
                <strong className={`text-${type === 'income' ? 'success' : 'danger'}`}>
                    {type === 'expense' && '-'}
                    {currencyFormatter.format(value)}
                </strong>
            </td>

            <td className="text-right">
                <button className="btn btn-outline-secondary btn-sm text-muted" onClick={() => setEditingEntry({_id, desc, value, created, category, type, userId, isRecurrent})}>
                    <i className="fas fa-edit"/>
                </button>
            </td>
        </tr>
    );
}

const Pagination = ({pageLinks, setPage, page}) => (
    <nav className="d-flex justify-content-between align-items-center">
        <span>Page {page + 1} out of {pageLinks.length}</span>
        <ul className="pagination mb-0 mr-2">
            {pageLinks.length && pageLinks.map((_, i) =>
                <li className={`page-item ${page === i ? 'active' : ''}`} key={i} onClick={() => setPage(i)}>
                    <span className="page-link">{i + 1}</span>
                </li>
            )}
        </ul>
    </nav>
);

const CSVExportBtn = ({csv}) => (<a
    className="btn btn-secondary btn-sm"
    href={`data:text/csv;charset=utf8,${encodeURIComponent(csv)}`}
    download={`${moment().format('DD-MM-YYYY')}-Entries.csv`}>
    <i className="fas fa-download mr-2"/>Download CSV</a>
);
