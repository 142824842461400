import React from 'react';
import {Provider} from 'mobx-react';

import {AuthStore, EntriesStore, UsersStore} from '../stores';

import {NavBar} from "./NavBar";
import {Messages} from "./Messages";
import {Router} from "./Router";
import {NotificationStore} from "../stores";


const entriesStore = new EntriesStore();
const authStore = new AuthStore();
const usersStore = new UsersStore();
const notificationStore = new NotificationStore();

export const App = () => (
    <Provider
        entriesStore={entriesStore}
        authStore={authStore}
        usersStore={usersStore}
        notificationStore={notificationStore}
        className="app">
        <NavBar/>
        <Messages/>
        <Router/>
    </Provider>
);

