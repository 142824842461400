import React from "react";

import {FilterHeader} from "./FilterHeader";
import {Overview} from "./Overview";
import {List} from "./List";
import {MChart} from "./MChart";

export const Main = () => (
    <div className="container-fluid pb-5 main">
        <div className="row">
            <div className="col-12">
                <FilterHeader/>
            </div>
            <div className="col-sm-4">
                <MChart/>
            </div>
            <div className="col-sm-8">
                <Overview/>
                <List/>
            </div>
        </div>
    </div>
);
