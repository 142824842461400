import {makeAutoObservable, runInAction} from 'mobx';

import {MCalcService} from "../mcalcService";
import {config} from "../config";

function logger(...args) {
    console.log('====================================');
    console.log(...args);
    console.log('====================================');
}

export class UsersStore {
    users = [];

    constructor() {
        this.mcalcService = new MCalcService(config.apiUrl);

        makeAutoObservable(this);
    }

    getUsers = async () => {
        try {
            const data = await this.mcalcService.getUsers();

            runInAction(() => (this.users = data));
        } catch (e) {
            logger('Failed to fetch users', e)
        }
    }
}
