import {withStores} from "../stores";
import React from "react";

export const Messages = withStores(['authStore'],
    ({authStore}) => {
        if (authStore.message) {
            return (
                <div className="messages-alert">
                    <div
                        className={`alert alert-${authStore.message.type} animated fadeInUp faster d-flex justify-content-between align-items-center`}>
                        <span className="message">{authStore.message.text}</span>
                        <i className="fas fa-bell ml-3 animated tada delay-1s"/>
                    </div>
                </div>
            );
        }

        return null;
    });

