import React from "react";
import {HashRouter, Route, Routes} from "react-router-dom";

import {Main} from "./Main";
import {Login, Protected} from "./Login";

export const Router = () => (
    <HashRouter>
        <Routes>
            <Route exact path="/" element={<Login/>}/>
            <Route path="/entries" element={
                <Protected>
                    <Main/>
                </Protected>
            }/>
        </Routes>
    </HashRouter>
);
