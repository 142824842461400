import React, {useEffect, useState} from "react";
import * as moment from "moment";
import DatePicker from "react-datepicker";
import {withStores} from "../stores";
import {Switch} from "./Switch";

export const FilterHeader = withStores(['entriesStore', 'usersStore'],
    ({entriesStore, usersStore}) => {
        useEffect(() => {
            usersStore.getUsers();
        }, [usersStore]);

        const current = new Date();
        const defaultEndDate = new Date(current.getFullYear(), current.getMonth() + 1, 0);
        const defaultStartDate = new Date().setDate(1);

        const [startDate, setStartDate] = useState(defaultStartDate);
        const [endDate, setEndDate] = useState(defaultEndDate);
        const [userId, setUserId] = useState('');
        const [category, setCategory] = useState('');
        const [isRecurrent, setIsRecurrent] = useState(false);
        const [hidden, setHidden] = useState(true);
        const [search, setSearch] = useState('');


        useEffect(() => {
            const query = isRecurrent ? {isRecurrent} : {
                start: moment(startDate).set({h: 23, m: 59, s: 59}).subtract(1, 'day').toISOString(),
                end: moment(endDate).set({h: 23, m: 59, s: 59}).toISOString(),
                userId,
                category,
                search,
                isRecurrent: undefined
            };

            entriesStore.getEntries(query);
        }, [search, category, userId, startDate, endDate, isRecurrent, entriesStore]);

        useEffect(() => {
            entriesStore.getCategories();
        }, [entriesStore]);

        return (
            <div className="card mb-2 mt-4">
                <div className="card-header d-flex justify-content-between align-items-center text-muted">
                    <span><i className="fas fa-filter mr-1"/>Filters</span>
                    <button onClick={() => setHidden(!hidden)} className="btn btn-outline-secondary btn-sm text-muted">
                        {hidden ? 'Expand' : 'Collapse'}
                    </button>
                </div>
                <div className="card-body" hidden={hidden}>
                    <div className="row justify-content-between">
                        <div className="d-flex w-100 justify-content-end">
                            <Switch checked={isRecurrent} onChange={() => setIsRecurrent(!isRecurrent)} labelText={'Filter for recurrent entries'}/>
                        </div>
                        <div className="col-md-6">
                            <DateRangeSelect
                                endDate={endDate}
                                startDate={startDate}
                                setEndDate={setEndDate}
                                setStartDate={setStartDate}/>
                            <div className="btn-group d-flex mt-2">
                                <button type="button" className="btn btn-secondary w-50" onClick={() => {
                                    setStartDate(moment(startDate).set({
                                        h: 23,
                                        m: 59,
                                        s: 59
                                    }).subtract(1, 'month').toDate());
                                    setEndDate(moment(endDate).set({
                                        h: 23,
                                        m: 59,
                                        s: 59
                                    }).subtract(1, 'month').toDate());
                                }}>Previous Month
                                </button>
                                <button type="button" className="btn btn-secondary w-50" onClick={() => {
                                    setStartDate(moment(startDate).set({h: 23, m: 59, s: 59}).add(1, 'month').toDate());
                                    setEndDate(moment(endDate).set({h: 23, m: 59, s: 59}).add(1, 'month').toDate());
                                }}>Next Month
                                </button>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <UserFilter
                                        users={usersStore.users}
                                        userId={userId}
                                        setUserId={setUserId}/>
                                </div>
                                <div className="col-12 col-md-6">
                                    <CategoryFilter
                                        categories={entriesStore.categories}
                                        category={category}
                                        setCategory={setCategory}/>
                                </div>
                                <div className="col-12 col-md-12 mt-2">
                                    <Search onSubmit={e => {
                                        e.preventDefault();
                                        setSearch(e.target?.elements?.[0]?.value)
                                        e.target.reset();
                                    }}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
);

function Search( {onSubmit}) {
    return <form onSubmit={onSubmit}>
        <div className="input-group">
            <div className="input-group-prepend">
                <span className="input-group-text"><i className="fas fa-search"/></span>
            </div>
            <input
                className="form-control"
                placeholder="search"
            />
        </div>
    </form>;
}

const CategoryFilter = ({categories, category, setCategory}) => (
    <div className="input-group mt-2 mt-md-0">
        <div className="input-group-prepend">
            <span className="input-group-text"><i className="fas fa-list-alt"/></span>
        </div>
        <select
            className="form-control"
            value={category}
            onChange={e => setCategory(e.target.value)}>

            <option value="">All Categories</option>

            {categories.map(cat => (
                <option
                    key={cat}
                    value={cat}>
                    {cat}
                </option>
            ))}
        </select>
    </div>
);

const UserFilter = ({users, userId, setUserId}) => (
    <div className="input-group mt-2 mt-md-0">
        <div className="input-group-prepend">
            <span className="input-group-text"><i className="fas fa-users"/></span>
        </div>
        <select
            className="form-control"
            value={userId}
            onChange={e => setUserId(e.target.value)}>

            <option value="">All Users</option>

            {users.map(user => (
                <option
                    key={user._id}
                    value={user._id}>
                    {user.username}
                </option>
            ))}
        </select>

    </div>
);

const DateRangeSelect = ({startDate, setStartDate, endDate, setEndDate}) => (
    <div className="d-flex">
        <div className="input-group input-group-date mr-1">
            <div className="input-group-prepend">
                <span className="input-group-text"><i className="fas fa-calendar-minus"/></span>
            </div>
            <div className="datePickerWrapper">
                <DatePicker className="custom-select form-control" dateFormat="dd/MM/yyyy" selected={startDate}
                            onChange={d => setStartDate(d)}
                            withPortal/>
            </div>
        </div>
        <div className="input-group input-group-date ml-1">
            <div className="input-group-prepend">
                <span className="input-group-text"><i className="fas fa-calendar-plus"/></span>
            </div>
            <div className="datePickerWrapper">
                <DatePicker className="form-control" dateFormat="dd/MM/yyyy" selected={endDate}
                            onChange={d => setEndDate(d)}
                            withPortal/>
            </div>
        </div>
    </div>
);
