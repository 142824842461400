export const Switch = ({checked, onChange, labelText}) => (
    <div className="d-flex align-items-center">
        {labelText && <label className="mr-2">{labelText}</label>}
        <label className="switch">
            <input type="checkbox"
                   checked={checked}
                   onChange={onChange}/>
            <span className="slider"/>
        </label>
    </div>
);
