import {withStores} from "../stores";
import React, {useEffect} from "react";

export const NavBar = withStores(['authStore', 'usersStore'],
    ({authStore, usersStore}) => {
        if (authStore.user && authStore.user.email) {
            const user = usersStore.users?.find(u => u._id.toString() === authStore.user.userId);
            return (
                <nav className="navbar navbar-dark bg-dark py-4 fixed-top">
                    <div className="container-fluid justify-content-between">
                        <div className="text-light d-flex align-items-center">
                            {user?.imageSrc && <img className="rounded-circle mr-3" width={40} src={user.imageSrc}/>}
                            <h4 className="mr-3 mb-0">Hi {authStore.user.username}</h4>
                            <NotificationsSwitch userId={authStore.user.userId}/>
                        </div>
                        <div className="d-flex">
                            <button
                                className="btn btn-outline-success btn-sm"
                                onClick={() => authStore.logout()}>
                                <i className="fas fa-sign-out-alt mr-2"/>Logout
                            </button>
                        </div>
                    </div>
                </nav>
            );
        } else {
            return null;
        }
    }
);

const NotificationsSwitch = withStores(['notificationStore'],
    ({notificationStore, userId}) => {
        useEffect(() => {
            notificationStore.isSubscribed(userId).catch(console.err)
        }, [notificationStore, userId]);

        const toggleNotify = () => notificationStore.notify
            ? notificationStore.unsubscribe(userId)
            : notificationStore.subscribe(userId);

        return (
            <div className="d-flex align-items-center">
                <label className="switch mr-2 mb-0">
                    <input type="checkbox"
                           checked={notificationStore.notify}
                           onChange={() => toggleNotify()}/>
                    <span className="slider"/>
                </label>
                <label className="mb-0">Notify {notificationStore.notify ? 'on' : 'off'}</label>
            </div>
        );
    });
