import React from 'react';
import {createRoot} from 'react-dom/client';
import './index.scss';
import {App} from './components';

import * as sw from './register';

const rootDomElement =document.getElementById('root')
const root = createRoot(rootDomElement);
root.render(<App/>);

sw.register();
